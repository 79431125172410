<template>
  <v-main>
    <div class="main">
      <v-row class="mt-15">
        <v-col sm="6" lg="3">
          <h2>Shelter/Clothing References</h2>
          <ul>
            <li>
              Keeping Warm During a Winter Power Outage
              <br />
              <a href="https://theprovidentprepper.org/6-lifesaving-tips-to-keep-warm-during-a-winter-power-outage/" target="_blank" rel="noreferrer noopener">https://theprovidentprepper.org</a>
            </li>
          </ul>
        </v-col>


        <v-col sm="6" lg="3">
          <h2>Heat/Light/Power/Fuel References</h2>
          <ul>
            <li>
              Dangerous Cold - Power Outage Do's and Don'ts
              <br />
              <a href="https://www.redcross.org/about-us/news-and-events/news/Heat-Your-Home-Safely-during-Cold-Weather.html" target="_blank" rel="noreferrer noopener">https://www.redcross.org</a>
            </li>
            <li>
              Keeping Warm During a Winter Power Outage
              <br />
              <a href="https://theprovidentprepper.org/6-lifesaving-tips-to-keep-warm-during-a-winter-power-outage/" target="_blank" rel="noreferrer noopener">https://theprovidentprepper.org</a>
            </li>
            <li>
              All Things EMP
              <br />
              <a href="https://disasterpreparer.com/informative-videos/" target="_blank" rel="noreferrer noopener">https://disasterpreparer.com</a>
            </li>
          </ul>
        </v-col>

        <v-col sm="6" lg="3">
          <h2>Transportation/Comm/Evac</h2>
          <ul>
            <li>
              American Radio Relay League - Ham Radio
              <br />
              <a href="https://www.arrl.org/" target="_blank" rel="noreferrer noopener">https://www.arrl.org/</a>
            </li>
            <li>
              Bug Out Vehicle Considerations
              <br />
              <a href="https://www.tactical.com/a-beginners-guide-to-bug-out-vehicles/" target="_blank" rel="noreferrer noopener">https://www.tactical.com</a>
            </li>
            <li>
              Plan to Evacuate
              <br />
              <a href="https://www.ready.gov/evacuation" target="_blank" rel="noreferrer noopener">https://www.ready.gov/evacuation</a>
            </li>
          </ul>
        </v-col>
        <v-col sm="6" lg="3">
          <h2>Spirtual/Mental References</h2>
          <ul>
            <li>
              Tips for Becoming Emotionally Resilient
              <br />
              <a href="https://www.churchofjesuschrist.org/life/tips-for-becoming-emotionally-resilient?lang=eng" target="_blank" rel="noreferrer noopener">https://www.churchofjesuschrist.org</a>
            </li>
          </ul>
        </v-col>

        <v-col sm="6" lg="3">
          <h2>Health/Safety/Security References</h2>
          <ul>
            <li>
              Red Cross First Aid Training
              <br />
              <a href="https://www.redcross.org/take-a-class/first-aid/first-aid-training" target="_blank" rel="noreferrer noopener">hhttps://www.redcross.org</a>
            </li>
          </ul>
        </v-col>
        <v-col sm="6" lg="3">
          <h2>Finance/Legal References</h2>
          <ul>
            <li>
              Tips for Being Financially Prepared
              <br />
              <a href="https://www.churchofjesuschrist.org/life/tips-for-being-financially-prepared?lang=eng" target="_blank" rel="noreferrer noopener">https://www.churchofjesuschrist.org</a>
            </li>
          </ul>
        </v-col>
        <v-col sm="6" lg="3">
          <h2>Knowledge/Tools References</h2>
          <ul>
            <li>
             Personal and Family Emergency Preparedness Planning Guide PDF
              <br />
              <a href="https://www.churchofjesuschrist.org/bc/content/shared/english/life-help/Personal-and-Family-Emergency-Preparedness-Planning-Guide.pdf" target="_blank" rel="noreferrer noopener">https://www.churchofjesuschrist.org</a>
            </li>
          </ul>
        </v-col>
        <v-col sm="6" lg="3">
          <h2>Food/Water/Special Needs References</h2>
          <ul>
            <li>
              EPA Ground and Drinking Water
              <br />
              <a href="https://www.epa.gov/ground-water-and-drinking-water/home-drinking-water-filtration-fact-sheet" target="_blank" rel="noreferrer noopener">https://www.epa.gov</a>
            </li>
            <li>
              CDC Drinking Water
              <br />
              <a href="https://www.cdc.gov/healthywater/drinking/index.html" target="_blank" rel="noreferrer noopener">https://www.cdc.gov</a>
            </li>
            <li>
              All is Safely Gathered In
              <br />
              <a href="https://www.churchofjesuschrist.org/bc/content/shared/content/english/pdf/language-materials/04008_eng.pdf" target="_blank" rel="noreferrer noopener">https://www.churchofjesuschrist.org</a>
            </li>
            <li>
              USDA Complete Guide to Home Canning 2015 Revison
              <br />
              <a href="https://nchfp.uga.edu/publications/publications_usda.html#gsc.tab=0" target="_blank" rel="noreferrer noopener">https://nchfp.uga.edu</a>
            </li>
            <li>
              LDS Food Storage Centers
              <br />
              <a href="https://providentliving.churchofjesuschrist.org/food-storage?lang=eng" target="_blank" rel="noreferrer noopener">https://providentliving.churchofjesuschrist.org</a>
            </li>
            <li>
              Food Storage Calculator
              <br />
              <a href="https://providentliving.com/preparedness/food-storage/foodcalc/" target="_blank" rel="noreferrer noopener">https://providentliving.com</a>
            </li>
          </ul>
        </v-col>
        <v-col sm="6" lg="3">
          <h2>Overall/General References</h2>
          <ul>
            <li>
              Be Ready Utah
              <br />
              <a href="https://beready.utah.gov/" target="_blank" rel="noreferrer noopener">https://beready.utah.gov/</a>
            </li>
            <li>
              Temporal Preparedness Resources
              <br />
              <a href="https://www.churchofjesuschrist.org/life/temporal-preparedness-resources?lang=eng" target="_blank" rel="noreferrer noopener">https://www.churchofjesuschrist.org</a>
            </li>
            <li>
              Local Area Preparedness Guides
              <br />
              <a href="https://www.churchofjesuschrist.org/life/AreaPreparednessGuides?lang=eng" target="_blank" rel="noreferrer noopener">https://www.churchofjesuschrist.org</a>
            </li>
            <li>
              Ready.gov
              <br />
              <a href="https://www.ready.gov/" target="_blank" rel="noreferrer noopener">https://www.ready.gov/</a>
              <br />
              <a href="https://www.ready.gov/be-informed" target="_blank" rel="noreferrer noopener">https://www.ready.gov/be-informed</a>
            </li>
            <li>
              Red Cross
              <br />
              <a href="https://www.redcross.org/" target="_blank" rel="noreferrer noopener">https://www.redcross.org/</a>
            </li>
            <li>
              Center for Disease Control
              <br />
              <a href="https://www.cdc.gov/" target="_blank" rel="noreferrer noopener">https://www.cdc.gov/</a>
            </li>
            <li>
              Department of Homeland Security
              <br />
              <a href="https://www.dhs.gov/" target="_blank" rel="noreferrer noopener">https://www.dhs.gov/</a>
            </li>
            <li>
              World Health Organization
              <br />
              <a href="https://www.who.int/" target="_blank" rel="noreferrer noopener">https://www.who.int/</a>
            </li>
            <li>
              FEMA
              <br />
              <a href="https://www.fema.gov" target="_blank" rel="noreferrer noopener">https://www.fema.gov</a>
              <br />
              <a href="https://www.fema.gov/pdf/library/pfd.pdf" target="_blank" rel="noreferrer noopener">https://www.fema.gov/pdf</a>
            </li>
          </ul>
        </v-col>

      </v-row>
    </div>
  </v-main>
</template>

<script>
import data from '../assets/data.json'

export default {
  name: 'App',
  components: {},
  data: () => ({
    categories: data.categories.filter((category) => category.links?.length)
  }),
  methods: {},
  watch: {},
  created() {}
}
</script>

<style scoped>
.main {
  padding: 0 8px;
}
</style>
